@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;1,200&display=swap');

@layer components {
    .md-content h1 {
        @apply text-2xl;
    }
    .md-content h2 {
        @apply text-xl;
    }
    .md-content h3 {
        @apply text-lg;
    }
    .md-content li  {
        @apply list-disc;
        @apply list-inside;
    }
    .md-content code  {
        @apply bg-gray-200;
    }
    .md-content ol  {
        @apply list-decimal;
    
    }
    .md-content b  {
        @apply font-semibold;
    }
    .md-content u  {
        @apply underline;
    }
}
